import { Col, Row } from 'antd';
import Modal from "antd/lib/modal/Modal";
import './_modal.scss';


interface Props {
    isModalOpen?: boolean;
    cancelHandler?: () => void;
    modalClass?: string;
    title?: string;
    children?: any;
    width?: number;
    closeable?: boolean;
}

// useEffect(() => {
//     document.body.style.overflow = 'hidden';
//     return () => document.body.style.overflow = 'unset';
// }, [])

const CustomModal: React.FC<Props> = ({ isModalOpen, cancelHandler, modalClass, title, children, width = 810, closeable = true }) => {
    return (
        <Modal
            destroyOnClose
            footer={null}
            open={isModalOpen}
            width={width}
            closable={closeable}
            className={`modal ${modalClass}`}
            onCancel={cancelHandler}
        >
            <div className="modal-body">
                <Row justify='space-between'>
                    <Col className='modal-title'>
                        {title}
                    </Col>
                </Row>
                <hr className='modal-divider' />
                <div className='modal-content'>
                    {children}
                </div>
            </div>
        </Modal >
    )
}

export default CustomModal;
