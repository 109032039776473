import { Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import api from "../../api/api";
import { formatPhone } from "../../utils/helper";

interface Props {
    checkoutForm: any;
    setId: any;
    errorMessage: any;
    setErrorMessage: any;
}


const CheckoutForm: React.FC<Props> = ({ checkoutForm, setId, errorMessage, setErrorMessage }) => {
    const getFormData = () => {
        api.user.profile()
            .then(res => {
                checkoutForm.setFieldsValue({ ...res?.data, phone: formatPhone(res?.data?.phone) })
                setId(res?.data?.id);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        getFormData();
    }, []);

    useEffect(() => {
        console.log(errorMessage);
    }, [errorMessage]);

    const onInputChangeHandler = (key: string) => {
        if (Object.keys(errorMessage)?.includes(key) && errorMessage[key] !== '') {
            setErrorMessage((prev: object) => {
                return { ...prev, [key]: '' };
            });
        }
    };

    return (
        <div>
            <Row gutter={24}>
                <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                        label="Name"
                        labelCol={{ span: 24 }}
                        name="first_name"
                        className="label"
                        validateTrigger="onBlur"
                        validateFirst
                        rules={[{ required: true, message: "Please enter your full name!" }]}
                    >
                        <Input
                            placeholder="John Doe"
                        />
                    </Form.Item>
                    {errorMessage?.name && (
                        <div role="alert" className="ant-form-item-explain-error error-backend">
                            {errorMessage?.name}
                        </div>
                    )}
                </Col>

                <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                        label="Email"
                        labelCol={{ span: 24 }}
                        name="email"
                        className="label"
                        validateTrigger="onChange"
                        validateFirst
                        rules={[
                            { required: true, message: "Please enter your personal email address!" },
                            { type: 'email', message: 'Please enter valid email' },
                        ]}
                    >
                        <Input
                            placeholder="johndoe@healthysure.in"
                            onChange={() => {
                                onInputChangeHandler('email');
                            }}
                        />
                    </Form.Item>
                    {errorMessage?.email && (
                        <div role="alert" className="ant-form-item-explain-error error-backend">
                            {errorMessage?.email}
                        </div>
                    )}
                </Col>

                <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                        label="Personal Mobile Number"
                        labelCol={{ span: 24 }}
                        name="phone"
                        className="label"
                        validateTrigger="onChange"
                        validateFirst
                        rules={[
                            { required: true, message: "Please enter your personal mobile number!" },
                            { pattern: RegExp('[6-9]{1}[0-9]{9}'), message: 'Please enter valid mobile number' },
                            { len: 10, message: 'Phone number should be 10 character long' },
                        ]}
                    >
                        <Input
                            onChange={() => {
                                onInputChangeHandler('phone');
                            }}
                            addonBefore={'+91'}
                            placeholder="9876543210"
                            disabled
                        />
                    </Form.Item>
                    {errorMessage?.phone && (
                        <div role="alert" className="ant-form-item-explain-error error-backend">
                            {errorMessage?.phone}
                        </div>
                    )}

                </Col>

                <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                        label="Company Name"
                        labelCol={{ span: 24 }}
                        name="company_name"
                        className="label"
                        validateTrigger="onBlur"
                        validateFirst
                    >
                        <Input
                            placeholder="Healthysure"
                        />
                    </Form.Item>
                    {errorMessage?.company_name && (
                        <div role="alert" className="ant-form-item-explain-error error-backend">
                            {errorMessage?.company_name}
                        </div>
                    )}

                </Col>

            </Row>
        </div>
    )
}

export default CheckoutForm;
