import axios from 'axios';
import ReactGA from "react-ga4";
import { errorNotification } from '../components/UI/Toast/Toast';

let LOCAL_ITEMS = ['retailAccessToken', 'retailRefreshToken'];

export const getParameterByName = (name: string, url: string) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getParametersBySubstring = (name: string, url: string) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let parts = window.location.search.slice(1).split('&');
  let result: string[] = [];
  parts.forEach((pair: any) => {
    pair = pair.split('=');
    if (pair[0].indexOf(name) > -1) {
      result.push(decodeURIComponent(pair[1]));
    }
  });
  return result.length ? result : null;
};

const STORAGE_TYPE = 'session';
export const setLocalData = (key: string, value: any) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  window.sessionStorage.setItem(key, value);
  // if (STORAGE_TYPE === 'local') {
  //   window.localStorage.setItem(key, value);
  // } else if (STORAGE_TYPE === 'session') {
  //   window.sessionStorage.setItem(key, value);
  // }
};

export const getLocalData = (key: any) => {
  let returnData: any = window.sessionStorage.getItem(key) || null;
  try {
    returnData = JSON.parse(returnData);
  } catch (e) {
    return returnData;
  }
  return returnData;
  // if (STORAGE_TYPE === 'local') {
  //   let returnData: any = window.localStorage.getItem(key) || null;
  //   try {
  //     returnData = JSON.parse(returnData);
  //   } catch (e) {
  //     return returnData;
  //   }
  //   return returnData;
  // } else if (STORAGE_TYPE === 'session') {
  //   let returnData: any = window.sessionStorage.getItem(key) || null;
  //   try {
  //     returnData = JSON.parse(returnData);
  //   } catch (e) {
  //     return returnData;
  //   }
  //   return returnData;
  // }
};

export const deleteLocalData = (key: string) => {
  window.sessionStorage.removeItem(key);
  // if (STORAGE_TYPE === 'local') {
  //   window.localStorage.removeItem(key);
  // } else if (STORAGE_TYPE === 'session') {
  //   window.sessionStorage.removeItem(key);
  // }
};

export const clearLocalData = () => {
  LOCAL_ITEMS.forEach((item) => {
    window.sessionStorage.removeItem(item);
  });
  // if (STORAGE_TYPE === 'local') {
  //   LOCAL_ITEMS.forEach((item) => {
  //     window.localStorage.removeItem(item);
  //   });
  // } else if (STORAGE_TYPE === 'session') {
  //   LOCAL_ITEMS.forEach((item) => {
  //     window.sessionStorage.removeItem(item);
  //   });
  // }
};

export const getAccessToken = () => {
  return getLocalData('retailAccessToken') || null;
};

export const setAccessToken = (token: string) => {
  setLocalData('retailAccessToken', token);
};

export const setRefreshToken = (token: string) => {
  setLocalData('retailRefreshToken', token);
};

export const getRefreshToken = () => {
  return getLocalData('retailRefreshToken') || null;
};

export const removeAxiosHeaders = () => {
  delete axios.defaults.headers.common['Authorization'];
};

export const setAxiosHeaders = (token = getAccessToken()) => {
  (function () {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  })();
};

export const errorHandler = (response: any) => {
  if (typeof response?.data?.error === 'string') {
    errorNotification(response?.data?.error);
  } if (typeof response?.error === 'string') {
    errorNotification(response?.error);
  } else if (response?.data?.error?.non_field_error) {
    errorNotification(response?.data?.error?.non_field_error?.[0]);
  } else if (response?.error?.non_field_errors) {
    errorNotification(response?.error?.non_field_errors?.[0]);
  } else if (response?.data?.error?.error_description) {
    errorNotification(response?.data?.error?.error_description);
  } else if (response?.detail) {
    errorNotification(response?.detail);
  }
};

// login	: method 
// add_to_cart	: items, value
// remove_from_cart	:  items, value
// view_cart : items, value
// begin_checkout : items, value
// purchase : transaction_id, value
export const useAnalyticsEventTracker = (category: string, params?: object) => ReactGA.event(category, params);

export const formatPhone = (phone?: string, digits = 10) => {
  if (!phone) return '';
  return phone.replace(/[^0-9]/g, '').slice(phone.length - digits);
}
