import { Col, Image, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { trackData } from "../../utils/trackers";
import Button from "../UI/Button/Button";
import './_productCard.scss';

interface Props {
    data?: any;
}

const ProductCard: React.FC<Props> = ({ data }) => {
    const navigate = useNavigate(),
        { partner } = useParams();

    return (
        <div className="product-card">
            <Row className="web">
                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={0}>
                    <Image className="image" src={data?.product_image} preview={false} />
                </Col>
                <Col xxl={16} xl={16} lg={16} md={16} sm={16} xs={0} className='content'>
                    <div className="title">{data?.product_name}</div>
                    <div className="secondary-text">Powered by : {data?.provider_name}</div>
                    <div className="description">{data?.product_description}</div>
                    <hr className="divider" />
                    <div className='know-more-cta'>
                        <Button type="secondary" onClick={() => {
                            trackData('Explore Offerings', { location: 'product', product: data?.product_name });
                            navigate(`/${partner}/offerings`)
                        }}>Know more</Button>
                    </div>
                </Col>
            </Row>
            <Row align="middle" className="mobile">
                <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={6}>
                    <Image className="image" src={data?.product_image} preview={false} />
                </Col>
                <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={18}>
                    <div className="title">{data?.product_name}</div>
                    <div className="secondary-text">Powered by : {data?.provider_name}</div>
                </Col>
                <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={24}>
                    <div className="description">{data?.product_description}</div>
                    <hr className="divider" />
                    <div className='know-more-cta'>
                        <Button type="secondary" onClick={() => navigate(`/${partner}/offerings`)}>Know more</Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ProductCard;
