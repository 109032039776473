import { Col, Image, Row } from 'antd';
import DOMPurify from 'dompurify';
import { BRAND_NAME } from '../../constants/config';
import CustomModal from '../Modal/Modal';
import './_productDetailsModal.scss';


interface Props {
    isModalOpen?: boolean;
    cancelHandler?: () => void;
    confirmHandler?: () => void;
    data?: any;
}

const createMarkup = (html: any) => {
    return {
        __html: DOMPurify.sanitize(html)
    }
}

const benefits = [
    {
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },

]



const ProductDetailsModal: React.FC<Props> = ({ isModalOpen, cancelHandler, data }) => {
    const ProductDetailsModalContent = (
        <div>
            {data?.product_description}

            <div className='accordian'>
                <div className='accordian-title'>What is offered</div>
                <div className='accordian-content'>
                    {/* {benefits?.map((item: any, index: number) => {
                        return ( */}
                    <Row
                        className="item"
                        justify='space-around'
                        key={data?.id}
                        align='middle'
                    >
                        {/* <Col span={1}>
                            <Row justify='center'>
                                <Image className='image' src={checkmark} preview={false} width={18} height={18} />
                            </Row>
                        </Col> */}
                        <Col span={22}>
                            <div className="description" dangerouslySetInnerHTML={createMarkup(data?.offer_detail)}></div>
                        </Col>
                    </Row>
                    {/* );
                    })} */}
                </div>
            </div>

            <Row className='powered-by' gutter={8} align='middle'>
                <Col>Powered by:</Col>
                <Col>
                    <Image src={data?.provider_image} preview={false} width={56} alt={`${data?.provider_name} x ${BRAND_NAME}`} />
                </Col>
            </Row>
        </div>
    );


    return (
        <CustomModal
            isModalOpen={isModalOpen}
            cancelHandler={cancelHandler}
            modalClass='product-details-modal'
            title={data?.product_name}
            children={ProductDetailsModalContent}
        />
    )
}

export default ProductDetailsModal;
