import { Col, Row } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { DEXTERSLUG, MAIN_SITE_URL_ENQUIRY } from '../../constants/config';
import Button from '../UI/Button/Button';


const FooterBtn: React.FC = () => {
  const { partner } = useParams();
  return (
    <div>
      {partner === DEXTERSLUG && <div className='sticky-footer' >
        <Row justify='end' align='middle'>
          <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
            <Button block type='primary' onClick={() => window.open(MAIN_SITE_URL_ENQUIRY + partner, '_blank')}>Enquire for Group Health Insurance</Button>
          </Col>
        </Row>
      </div>}
    </div>
  )
};

export default FooterBtn;
