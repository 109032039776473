import { Result } from "antd";
import React, { useEffect } from "react";
import { MAIN_SITE_URL } from "../constants/config";

const RedirectToHome: React.FC = () => {

  useEffect(() => {
    window.location.href = MAIN_SITE_URL;
  }, []
  )

  return (
    <React.Fragment>
      <Result
        status="warning"
        title="Redirecting you to home page"
      />
    </React.Fragment>
  )
};

export default RedirectToHome;
