import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { CASHFREE_URL, HDFCCOUPON } from "../../constants/config";
import { errorHandler } from "../../utils/helper";
import TnCModal from "../TnCModal/TnCModal";
import Button from "../UI/Button/Button";
import { successNotification } from "../UI/Toast/Toast";
import './_checkout-summary-card.scss';
interface Props {
    activeTab: string | undefined;
    changeActiveTab: any;
    cartData: any;
    checkoutForm: any;
    id: any;
    setErrorMessage: any;
}
declare global {
    interface Window {
        Cashfree: any;
    }
}

const CheckoutSummaryCard: React.FC<Props> = ({ activeTab, changeActiveTab, cartData, checkoutForm, id, setErrorMessage }) => {
    // console.log(CASHFREE_URL);
    const [isModalOpen, setIsModalOpen] = useState(false),
        navigate = useNavigate(),
        { partner } = useParams(),
        total_cost = cartData?.map((item: any) => item?.products?.total_cost).reduce((a: any, b: any) => a + b, 0),
        total_offer_cost = cartData?.map((item: any) => item?.products?.total_offer_cost).reduce((a: any, b: any) => a + b, 0),
        [buttonLoading, setButtonLoading] = useState(false),
        checkoutStart = () => {
            ReactGA.event('begin_checkout', { currency: 'INR', value: total_offer_cost });
            changeActiveTab('details');
        },
        [open, setOpen] = useState(false),
        onSubmitHandler = () => {
            if (partner !== 'hdfc') {
                checkoutForm.validateFields()
                    .then((values: any) => {
                        setButtonLoading(true);
                        api.user.updateProfile({ ...values, slug: partner })
                            .then(res => {
                                api.payments.getPaymentLink(
                                    {
                                        id: id,
                                        phone: checkoutForm.getFieldValue('phone'),
                                        order_amount: total_offer_cost,
                                        email: checkoutForm.getFieldValue('email'),
                                        slug: partner,
                                        name: checkoutForm.getFieldValue('first_name'),
                                    })
                                    .then(result => {
                                        let cf = new window.Cashfree(result?.payment_session_id);
                                        successNotification('Redirecting you to payment page');
                                        setButtonLoading(false);
                                        cf.redirect();
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        setButtonLoading(false);
                                        errorHandler(err?.response?.data);
                                    });
                            })
                            .catch(err => {
                                console.log(err);
                                setErrorMessage(err?.response?.data?.error?.[0]);
                                setButtonLoading(false);
                            });
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            }
            else {
                checkoutForm.validateFields()
                    .then((values: any) => {
                        setButtonLoading(true);
                        api.user.updateProfile({ ...values, slug: partner })
                            .then(res => {
                                api.payments.getPaymentLink(
                                    {
                                        id: id,
                                        phone: checkoutForm.getFieldValue('phone'),
                                        order_amount: total_cost,
                                        email: checkoutForm.getFieldValue('email'),
                                        slug: partner,
                                        name: checkoutForm.getFieldValue('first_name'),
                                    })
                                    .then(result => {
                                        let cf = new window.Cashfree(result?.payment_session_id);
                                        successNotification('Redirecting you to payment page');
                                        setButtonLoading(false);
                                        cf.redirect();
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        setButtonLoading(false);
                                        errorHandler(err?.response?.data);
                                    });
                            })
                            .catch(err => {
                                console.log(err);
                                setButtonLoading(false);
                            });
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            }
        }

    useEffect(() => {
        var script = document.createElement('script');
        script.src = CASHFREE_URL;
        document.head.insertAdjacentElement('beforeend', script);
    }, []);

    const summary = [
        {
            key: "Total Price (MRP)",
            value: total_cost?.toLocaleString('en-IN'),
            class: "primary-text",
        },
        {
            key: "Discount",
            value: (total_cost - total_offer_cost)?.toLocaleString('en-IN'),
            class: "primary-text",
        },
        {
            key: "Total Payable",
            value: total_offer_cost?.toLocaleString('en-IN'),
            class: "price",
        }
    ]

    return (
        <div>
            <TnCModal cancelHandler={() => setIsModalOpen(false)} isModalOpen={isModalOpen} />
            <div className="checkout-summary-card">
                {summary.map((item, index) => {
                    return (
                        <span key={index}>
                            {index === 2 ? <hr className="summary-item divider" /> : null}
                            <Row key={index} className="summary-item" justify="space-between" align="bottom">
                                <Col className="primary-text">{item.key}</Col>
                                <Col className={item.class}>{index === 1 ? "-" : null} <span className="amount">₹{item.value}</span></Col>
                            </Row>
                        </span>
                    )
                })}

                <div className="primary-text spacing">
                    {partner === 'hdfc' && <>Use code <b>{HDFCCOUPON}</b> at checkout to get the discount (50% off) on your order*<br /><br /></>}
                    You'll be redirected to a secure payment gateway to complete your purchase.
                    <br /><br />
                    Please read our <span className="blue-cta" onClick={() => setIsModalOpen(true)}>Terms and Conditions</span> before purchase.
                </div>
                <Button type="primary" loading={buttonLoading} disabled={cartData?.length === 0} block onClick={activeTab === 'cart' ? () => checkoutStart() : () => onSubmitHandler()}>Proceed&nbsp;</Button>
                {partner === 'hdfc' &&
                    <>
                        <br /><span style={{ fontSize: 12 }}>*Offer exclusively for HDFC debit and credit cards.</span>
                    </>
                }
            </div>
        </div>
    )
}

export default CheckoutSummaryCard;