import { Image, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import emptycart from '../../assets/images/emptycart.svg';
import Button from '../UI/Button/Button';


const EmptyCart: React.FC = ({ }) => {
    const { partner } = useParams(),
        navigate = useNavigate();

    return (
        <div className='empty-cart'>
            <Row justify='center'>
                <div className='primary-heading'>Your cart is empty</div>
            </Row>
            <Row justify='center'>
                <div className='subtitle mt-16'>There are no items in your cart. Would you like to explore some products?</div>
            </Row>
            <Row justify='center'>
                <Image src={emptycart} preview={false} className='cta mt-40' onClick={() => navigate(`/${partner}/offerings`)} />
            </Row>
            <Row justify='center'>
                <div className='mt-32'><Button onClick={() => navigate(`/${partner}/offerings`)} type='primary'>Back to products</Button></div>
            </Row>
        </div>
    )
}

export default EmptyCart;


