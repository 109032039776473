import { Col, Form, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api/api';
import CheckoutForm from '../components/CheckoutForm/CheckoutForm';
import CheckoutProductCard from '../components/CheckoutProductCard/CheckoutProductCard';
import CheckoutSummaryCard from '../components/CheckoutSummaryCard/CheckoutSummaryCard';
import EmptyCart from '../components/EmptyCart/EmptyCart';
import FooterBtn from '../components/Modal/FooterBtn';
import LockModal from '../components/Modal/LockModal';
import ProductDetailsModal from '../components/ProductDetailsModal/ProductDetailsModal';
import Button from '../components/UI/Button/Button';
import TabIndicator from '../components/UI/TabIndicator/TabIndicator';
import { errorNotification } from '../components/UI/Toast/Toast';
import { updateCartCount } from '../store/auth';
import { errorHandler } from '../utils/helper';

const Checkout: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false),
    cartCount = useSelector((state: any) => state?.auth?.cartCount),
    isLoggedIn = useSelector((state: any) => state?.auth?.isLoggedIn),
    [cartData, setCartData] = useState(null as any),
    [errorMessage, setErrorMessage] = useState('' as any),
    [tabData, setTabData] = useState(
      [
        { title: `Your cart (${cartCount} items)`, name: 'cart', isActive: true },
        { title: 'Personal Details', name: 'details', isActive: false },
      ] as any),
    [id, setId] = useState([] as any),
    dispatch = useDispatch(),
    { partner } = useParams(),
    navigate = useNavigate(),
    getCartData = () => {
      api.cart.getCart({ slug: partner })
        .then((res) => {
          dispatch(updateCartCount(partner))
          setCartData(res?.data);
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
          console.log(err);
        })
    },
    [checkoutForm] = Form.useForm();

  useEffect(() => {
    updateCartCounter();
  }, [cartCount])

  useEffect(() => {
    // console.log(tabData);
    if (isLoggedIn) {
      window.scrollTo(0, 0);
      // console.log('tabData calls');
      getCartData();
    }
    else {
      errorNotification('Please login to view your cart');
      navigate(`/${partner}/offerings`);
    }
  }, [])

  const changeActiveTab = (name: string) => {
    setTabData(
      tabData?.map((item: any) => {
        if (item?.name === name) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
        return item;
      })
    );
  }

  const updateCartCounter = () => {
    setTabData(
      tabData?.map((item: any) => {
        if (item?.name === 'cart') {
          item.title = `Your cart (${cartCount} items)`;
        }
        return item;
      })
    );
  }
  return (
    <div className='page'>
      <LockModal />
      <ProductDetailsModal cancelHandler={() => setIsModalOpen(false)} isModalOpen={isModalOpen} />
      <div className='checkout-card container'>
        <Row justify='start'>
          {cartData?.length > 0 &&
            <Col>
              <div className='secondary-heading'>Checkout</div>
            </Col>
          }
        </Row>
        {cartData?.length > 0 && <Row className='mt-16'>
          <TabIndicator data={tabData} changeActiveTab={changeActiveTab} />
        </Row>}


        <Row justify='start' className='mt-48' gutter={[24, 24]}>
          <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
            {tabData?.find((item: any) => item?.isActive === true)?.name === 'cart' &&
              <div>
                {cartData?.map((product: any, index: number) => (
                  <span key={index}><CheckoutProductCard data={product} getCartData={getCartData} /></span>
                ))}
                <Row justify='center' className='mt-24'>
                  {cartData === null
                    &&
                    <Spin />}
                </Row>
              </div>
            }

            {tabData?.find((item: any) => item?.isActive === true)?.name === 'details' &&
              <Form form={checkoutForm}>
                <CheckoutForm checkoutForm={checkoutForm} setId={setId} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
              </Form>
            }

            {cartData?.length > 0 && tabData.find((item: any) => item?.isActive === true)?.name === 'cart' &&
              <div className='show-on-mobile'>
                <div className='mt-32'>
                  <Button type='secondary' onClick={() => navigate(`/${partner}/offerings`)}>Add Products</Button></div>
              </div>}
          </Col>
          {cartData?.length > 0 &&
            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              <CheckoutSummaryCard id={id} activeTab={tabData?.find((item: any) => item?.isActive === true)?.name} changeActiveTab={changeActiveTab} cartData={cartData} checkoutForm={checkoutForm} setErrorMessage={setErrorMessage} />
            </Col>
          }
        </Row>

        {cartData?.length === 0 &&
          <Row justify='center' className='mt-24'>
            <EmptyCart />
          </Row>
        }

        {cartData?.length > 0 && tabData.find((item: any) => item?.isActive === true)?.name === 'cart' &&
          <div className='show-on-web'>
            <div className='mt-32'>
              <Button type='secondary' onClick={() => navigate(`/${partner}/offerings`)}>Add Products</Button></div>
          </div>}
      </div>

      <FooterBtn />
    </div >
  )
};

export default Checkout;
