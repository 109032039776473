import { Col, Form, Image, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dexterLogo from '../../assets/icons/dexterlogo.png';
import logo from '../../assets/icons/logo.svg';
import { DEXTERCOUPON, DEXTERSLUG } from '../../constants/config';
import { getLocalData, setLocalData } from '../../utils/helper';
import Button from '../UI/Button/Button';
import { errorNotification, successNotification } from '../UI/Toast/Toast';
import CustomModal from './Modal';


const LockModal: React.FC = () => {
  const { partner } = useParams(),
    [ccForm] = Form.useForm(),
    [lock, setLock] = useState<boolean | undefined>(undefined),
    checkCoupon = () => {
      ccForm.validateFields().then((values) => {
        if (values?.coupon === DEXTERCOUPON) {
          setLocalData('cc', DEXTERCOUPON);
          setLock(false);
          successNotification('Page unlocked successfully');
        }
        else {
          errorNotification('Invalid coupon code');
          ccForm.resetFields(['coupon']);
        }
      }).catch((err) => {
        console.log(err);
      });
    };

  useEffect(() => {
    setLock((getLocalData('cc') !== DEXTERCOUPON && partner == DEXTERSLUG) ? true : false);
  }, [])

  useEffect(() => {
    document.getElementsByClassName('page')?.[0]?.classList.toggle('blurred', lock);
  }, [lock])


  return (
    <div>
      <CustomModal
        title={`Exclusive New Year's Offer`}
        isModalOpen={(lock) ? true : false}
        closeable={false}
        width={600}
      >
        <Row justify='space-around' className='show-on-web' gutter={[60, 20]}>
          <Col xxl={10} xl={10} lg={10} md={10}>
            <Image src={logo} preview={false} />
          </Col>
          <Col>X</Col>
          <Col xxl={10} xl={10} lg={10} md={10}>
            <Image src={dexterLogo} preview={false} />
          </Col>
        </Row>
        <div className='show-on-mobile'>
          <Row justify='center' className='show-on-mobile'>
            <Image src={logo} width={200} preview={false} />
          </Row>
          <Row justify='center' className='mt-16 show-on-mobile'>
            x
          </Row>
          <Row justify='center' className='mt-16 show-on-mobile'>
            <Image src={dexterLogo} width={200} preview={false} />
          </Row>
        </div>
        <Row justify='center' className='mt-32'>
          Enter the coupon code to unlock the page
        </Row>
        <Form
          form={ccForm}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              checkCoupon();
            }
          }}
        >
          <Row className='mt-32' gutter={40}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name='coupon'
                rules={[{ required: true, message: 'Please enter the coupon code' }]}
              >
                <Input placeholder='Enter the coupon code' />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Button type='primary' block onClick={checkCoupon}>Unlock offers</Button>
            </Col>
          </Row>
        </Form>
      </CustomModal>
    </div>
  )
};

export default LockModal;
