import { Col, Row, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api/api';
import providerlogo from '../assets/images/providerlogo.svg';
import BuyProductCard from '../components/BuyProductCard/BuyProductCard';
import FooterBtn from '../components/Modal/FooterBtn';
import LockModal from '../components/Modal/LockModal';
import ProductDetailsModal from '../components/ProductDetailsModal/ProductDetailsModal';
import { errorHandler } from '../utils/helper';


const partners = [
  { logo: providerlogo, name: 'Visit' },
  { logo: providerlogo, name: 'Visit' },
  { logo: providerlogo, name: 'Visit' },
  { logo: providerlogo, name: 'Visit' },
]
const Offerings: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false),
    [productData, setProductData] = useState([] as any),
    [loading, setLoading] = useState(false),
    { partner } = useParams(),
    getProducts = () => {
      let source = axios.CancelToken.source();
      api.partner.getProducts({ slug: partner }, source.token)
        .then((res) => {
          setProductData(res?.data?.results?.[0]?.products.sort((a: any, b: any) => {
            const productA = a.product_name.toUpperCase();
            const productB = b.product_name.toUpperCase();
            if (productA < productB) {
              return -1;
            } else if (productA > productB) {
              return 1;
            } else {
              return 0;
            }
          }));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorHandler(err?.response?.data);
          setLoading(false);
        })
    };
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    getProducts();
  }, [])

  return (
    <div className='page'>
      <LockModal />
      <ProductDetailsModal cancelHandler={() => setIsModalOpen(false)} isModalOpen={isModalOpen} />

      <div className='offerings-card container'>
        <div className='secondary-heading'>Explore our offerings&nbsp;{loading && <Spin />}</div>
        <Row justify='start'>


        </Row>
        <Row justify='start' className='mt-48 no-margin-sides' gutter={[24, 24]}>
          {productData?.map((product: any, index: number) => (
            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} key={index}>
              <BuyProductCard data={product} getProducts={getProducts} />
            </Col>
          ))}
        </Row>
      </div>
      <FooterBtn />

    </div >
  )
};

export default Offerings;
