import 'antd/dist/antd.css';
import { useEffect } from 'react';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';
import './App.scss';
import './antd-custom.scss';
import { REACT_APP_GA } from './constants/config';
import './helper.scss';
import Layout from './pages/Layout';
import Router from './router/Router';
import { trackData } from './utils/trackers';

if (REACT_APP_GA) {
  ReactGA.initialize(REACT_APP_GA);
}

if (window.location.host.startsWith("www")
) {
  console.log('redirecting...');
  console.log(window.location.protocol, window.location.host, window.location.pathname, window.location.search)
  window.location.href = window.location.protocol + "//" +
    window.location.host.replace('www.', '') + window.location.pathname + window.location.search;
}


function App(props: any) {
  const location = useLocation();

  useEffect(() => {
    trackData('View Page', { url: location.pathname });
  }, [location]);

  return (
    <Layout {...props}>
      <Router />
    </Layout>
  );
}

export default App;
