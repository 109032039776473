import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';
import {
  clearLocalData,
  getAccessToken, removeAxiosHeaders, setAccessToken, setAxiosHeaders, setRefreshToken
} from '../utils/helper';

const isUserLoggedIn = () => {
  const accessToken = getAccessToken();
  return accessToken ? true : false;
};

const fetchCartCount = createAsyncThunk(
  'auth/fetchCartCount',
  (slug: any) => {
    return api.cart.cartCount({
      slug: slug
    })
      .then((res) => res.data.items_count)
  }
)
interface TAuthState {
  phone: string | null;
  isLoggedIn: boolean;
  cartCount: any;
}

const initialState: TAuthState = {
  phone: null,
  isLoggedIn: getAccessToken(),
  cartCount: 0,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      setAccessToken(action.payload.accessToken);
      setRefreshToken(action.payload.refreshToken);
      setAxiosHeaders();
      state.isLoggedIn = true;
      state.phone = action.payload?.phone;
    },
    logout: (state) => {
      clearLocalData();
      removeAxiosHeaders();
      return { ...initialState, isLoggedIn: false };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCartCount.fulfilled, (state, action) => {
      state.cartCount = action.payload;
    })
  },
});

export const authActions = authSlice.actions;
export const updateCartCount = fetchCartCount;
export default authSlice.reducer;