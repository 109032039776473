import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { Col, Divider, Image, Modal, Row } from "antd";
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BRAND_NAME } from "../../constants/config";
import Button from "../UI/Button/Button";
import { successNotification } from "../UI/Toast/Toast";
import './_productCouponCard.scss';

interface Props {
    data: any
}

const ProductCouponCard: React.FC<Props> = ({ data }) => {
    const [visible, setVisible] = useState(false),
        onClose = () => setVisible(false),
        createMarkup = (html: any) => {
            return {
                __html: html,
            };
        };
    // console.log(data?.plan_code);
    return (
        <div>
            <Modal
                open={visible}
                footer={null}
                onCancel={onClose}
                className="avail-subscription-modal"
                width={'560px'}
                closable={false}
            >
                <div className='content'>
                    <Row justify="space-between" align="middle">
                        <span className="modal-title">{data?.product_name}</span>
                        <CloseOutlined width={16} onClick={onClose} />
                    </Row>
                    <div className="subtitle">{data?.product_description}</div>
                    <Divider className="m-divider" />

                    <Row justify="center">
                        {data?.coupons?.map((coupon: any, index: number) => (
                            <div className="coupon-code">
                                <span className="coupon-code-value">{coupon}</span>
                                <span className="copy-coupon-code">
                                    <CopyToClipboard text={coupon} onCopy={() => successNotification('Coupon code copied')}>
                                        <CopyOutlined />
                                    </CopyToClipboard>
                                </span>
                            </div>
                        ))}
                    </Row>


                    <div className="steps-title">Steps to avail benefits</div>

                    <div
                        className="steps"
                        dangerouslySetInnerHTML={createMarkup(data?.avail_steps)}
                    ></div>
                </div>
            </Modal>

            <div className="product-coupon-card">
                <Row align='top' gutter={24} className="show-on-web">
                    <Col xxl={4} xl={5} lg={5} md={5}>
                        <Image className="image" preview={false} alt={`${data?.product_image} with ${BRAND_NAME}`} src={data?.product_image} />
                    </Col>
                    <Col span={6}>
                        <div className="title">{data?.product_name}</div>
                        <div className="primary-text">{data?.product_description}</div>
                        {data?.plan_code && <div className="primary-text"><span className='label'>Plan</span> : {data?.plan_code}</div>}
                    </Col>
                    <Col span={3}>
                        <div className="label">Qty</div>
                        <div className="content">{data?.quantity}</div>
                    </Col>
                    {data?.coupons?.length > 0 &&
                        <Col span={5}>
                            <div className="label">Coupon Code</div>
                            {data?.coupons?.map((coupon: any, index: number) => (
                                <div key={index}>
                                    <span className="coupon-code">{coupon}</span><Divider type="vertical" />
                                    <CopyToClipboard
                                        text={coupon}
                                        onCopy={() => successNotification('Coupon code copied')}
                                    >
                                        <span className='copy-code cta'><CopyOutlined />&nbsp;Copy</span>
                                    </CopyToClipboard>
                                </div>
                            ))}
                        </Col>}

                    {data?.coupons?.length > 0 &&
                        <Col span={4}>
                            <Button type='secondary'
                                // onClick={() => window.location.href = data?.redeem_link}
                                onClick={() => setVisible(true)}
                            >Redeem Now</Button>
                        </Col>}
                </Row>
                <Row align='top' gutter={16} className="show-on-mobile">
                    <Col span={6}>
                        <Image className="image" preview={false} alt={`${data?.product_image} with ${BRAND_NAME}`} src={data?.product_image} />
                    </Col>
                    <Col span={18}>
                        <div className="title">{data?.product_name}</div>
                        <div className="primary-text">{data?.product_description}</div>
                        {data?.plan_code && <div className="primary-text"><span className='label'>Plan</span> : {data?.plan_code}</div>}

                        <div className="label">Qty</div>
                        <div className="content">{data?.quantity}</div>

                        <div className="label">Coupon Code</div>
                        {data?.coupons?.map((coupon: any, index: number) => (
                            <div key={index}>
                                <span className="coupon-code">{coupon}</span><Divider type="vertical" />
                                <CopyToClipboard
                                    text={coupon}
                                    onCopy={() => successNotification('Coupon code copied')}
                                >
                                    <span className='copy-code cta'><CopyOutlined />&nbsp;Copy</span>
                                </CopyToClipboard>
                            </div>
                        ))}
                        {
                            data?.coupons?.length === 0 && <div className="">
                                {data?.no_coupon_message}
                            </div>
                        }

                        {data?.coupons?.length > 0 &&
                            <div className="mt-16">
                                <Button type='secondary' block
                                    // onClick={() => window.location.href = data?.redeem_link}
                                    onClick={() => setVisible(true)}
                                >Redeem Now</Button>
                            </div>
                        }
                    </Col>
                </Row>
                <hr className="divider" />
            </div>
        </div>
    )
}

export default ProductCouponCard;
