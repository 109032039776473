import { Col, Image, Row } from 'antd';
import React from 'react';
import email from '../../assets/icons/email.svg';
import google from '../../assets/icons/Google.svg';
import inc42 from '../../assets/icons/Inc42.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import logo from '../../assets/icons/logo-inverse.svg';
import twitter from '../../assets/icons/twitter.svg';
import { MAIN_SITE_URL } from '../../constants/config';
import './_footer.scss';

const Footer: React.FC = () => {
  const footerLinks = [
    [
      {
        title: 'Home',
        link: MAIN_SITE_URL,
      },
      // {
      //   title: 'Get Quote',
      //   link: '#',
      // },
      {
        title: 'How it Works',
        link: MAIN_SITE_URL + '/how-it-works',
      },
      {
        title: 'FAQs',
        link: MAIN_SITE_URL + '/faq',
      }
    ],
    [
      {
        title: 'About Us',
        link: MAIN_SITE_URL + '/about-us',
      },
      {
        title: 'Careers',
        link: MAIN_SITE_URL + '/careers',
      },
      {
        title: 'Blog',
        link: MAIN_SITE_URL + '/blog',
      },
      // {
      //   key: 1,
      //   title: 'Join Us',
      //   link: '#',
      // },
      // {
      //   key: 1,
      //   title: 'Partner with Us',
      //   link: '#',
      // }
    ],
    [
      {
        title: 'Privacy Policy',
        link: MAIN_SITE_URL + '/privacy-policy',
      },
      {
        title: 'Terms of Use',
        link: MAIN_SITE_URL + '/terms-of-use',
      },
      {
        title: 'Refund Policy',
        link: MAIN_SITE_URL + '/refund-policy',
      },
      {
        title: 'Contact us',
        link: MAIN_SITE_URL + '/contact-us',
      }

    ]
  ],
    footerTitles = ['Platform', 'About', 'Information'],
    footerSocials = [
      {
        icon: email,
        link: 'mailto:care@healthysure.in',
      },
      // {
      //   key: 2,
      //   icon: facebook,
      //   link: '#',
      // },
      {
        icon: linkedin,
        link: 'https://www.linkedin.com/company/healthysure/',
      },
      {
        icon: twitter,
        link: 'https://twitter.com/teamhealthysure',
      }
    ],
    badges = [
      {
        icon: inc42,
        link: 'https://inc42.com/startups/30-startups-to-watch-startups-that-caught-our-eye-in-june-2022-fintech-edition/#cxrecs_s',
        alt: "Healthysure in Inc42's Startup to Watch",
      },
      {
        icon: google,
        link: 'https://goo.gl/maps/bvgfVG8VMFVagpwE6',
        alt: "Healthysure, 5 star service ratings by employees",
      }
    ]



  return (
    <div className='footer'>
      <Row justify='space-between' gutter={[0, 48]} align='top' className='container full-width'>
        <Col xxl={14} xl={14} lg={14} md={14} sm={24} xs={24}>
          <a href={MAIN_SITE_URL} target='_blank'><Image src={logo} preview={false} /></a>
          <div className="footer-tagline">
            India's most loved Employee Benefits Platform
          </div>
          <Row align="middle" gutter={24}>
            {footerSocials.map((social, index) => {
              return (
                <Col key={index}>
                  <a href={social.link} target='_blank'>
                    <Image src={social.icon} preview={false} />
                  </a>
                </Col>
              )
            })}
          </Row>
          <Row align="middle" gutter={24} className='footer-badges'>
            {badges.map((badge, index) => {
              return (
                <Col key={index}>
                  <a href={badge.link} target='_blank'>
                    <Image src={badge.icon} preview={false} alt={badge.alt} />
                  </a>
                </Col>
              )
            })}
          </Row>
        </Col>
        {footerLinks.map((links, index) => {
          return (
            <Col key={index} xxl={3} xl={3} lg={3} md={3} sm={8} xs={8}>
              <Row gutter={[0, 16]}>
                <Col span={24} className="footer-heading">
                  {footerTitles[index]}
                </Col>
                {links.map((link, index) => {
                  return (
                    <Col key={index} span={24}>
                      <a href={link.link} className="footer-links">{link.title}</a>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          )
        })}
        <Col span={24} className='footnote'>
          <Row className='text-center' align='middle' justify='center'>
            © All Rights Reserved. Healthysure @2022.
          </Row>
          <Row className='text-center' align='middle' justify='center'>
            Insurance solutions provided by Safetynet Insurance Service Private Limited.
          </Row>
          <Row className='text-center' align='middle' justify='center'>
            IRDAI Registered Corporate Agent (Composite) License No CA0785 valid till 19th December 2024
          </Row>
        </Col>
      </Row>
    </div>
  )
};

export default Footer;
