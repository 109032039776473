import { DeleteOutlined } from "@ant-design/icons";
import { Col, Image, Popconfirm, Row, Select } from "antd";
import { useState } from "react";
import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import { BRAND_NAME } from "../../constants/config";
import { errorHandler } from "../../utils/helper";
import PriceDisplay from "../UI/PriceDisplay/PriceDisplay";
import { successNotification } from "../UI/Toast/Toast";
import './_checkoutProductCard.scss';

interface Props {
    data: any
    getCartData: any
}

const CheckoutProductCard: React.FC<Props> = ({ data, getCartData }) => {
    // console.log(data);
    const [confimDialogVisible, setConfirmDialogVisible] = useState(false),
        { partner } = useParams(),
        removeProduct = () => {
            api.cart.clearCart(data?.id, { slug: partner })
                .then(res => {
                    getCartData();
                    ReactGA.event('remove_from_cart', { value: data?.offer_cost, currency: 'INR', items: [{ item_name: data?.product_name, item_variant: res?.data?.products?.plan_code, item_brand: data?.provider_name }] });
                    successNotification('Product removed from cart');
                })
                .catch(err => {
                    console.log(err);
                    errorHandler(err?.response?.data)
                })
        },
        updateCart = (quantity: number) => {
            api.cart.manageCart({
                quantity: quantity,
                category: data?.products?.category,
                service_id: data?.products?.service_id,
                type: 'set',
                slug: partner
            })
                .then(res => {
                    getCartData();
                    successNotification('Product quantity updated');
                })
                .catch(err => {
                    console.log(err);
                    errorHandler(err?.response?.data)
                })
        }

    return (
        <div className="checkout-product-card">
            <Row align='top' gutter={24} className="show-on-web">
                <Col span={5}>
                    <Image className="image" preview={false} alt={`${data?.products?.provider_name} x ${BRAND_NAME}`} src={data?.products?.product_image} />
                </Col>
                <Col span={12}>
                    <div className="title">{data?.products?.product_name}</div>
                    <div className="primary-text">{data?.products?.product_description}</div>
                    {data?.products?.plan_code && <div className="primary-text">Plan : {data?.products?.plan_code}</div>}
                    <Popconfirm
                        title="Are you sure to remove this product?"
                        onConfirm={() => removeProduct()}
                        onCancel={() => setConfirmDialogVisible(false)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="blue-cta mt-40" onClick={() => setConfirmDialogVisible(true)}><DeleteOutlined /> Remove</div>
                    </Popconfirm>
                </Col>
                <Col span={3}>
                    <Select
                        defaultValue={1}
                        style={{ width: 73 }}
                        onChange={(quantity) => { updateCart(quantity) }}
                        value={parseInt(data?.quantity)}
                        disabled={data?.products?.product_activation_type === 'api'}
                    >
                        {Array(10).fill(1).map((number, index) => {
                            return (
                                <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                            )
                        }
                        )}
                    </Select>
                </Col>
                <Col span={4}>
                    <PriceDisplay MRP={data?.products?.cost} OfferPrice={data?.products?.offer_cost} />
                </Col>
            </Row>
            <Row align='top' gutter={24} className="show-on-mobile">
                <Col span={6}>
                    <Image className="image" preview={false} alt={`${data?.products?.provider_name} x ${BRAND_NAME}`} src={data?.products?.product_image} />
                </Col>
                <Col span={18}>
                    <div className="title">{data?.products?.product_name}</div>
                    <div className="primary-text description">{data?.products?.product_description}</div>
                    <div className="primary-text description">{data?.products?.product_description}</div>
                    <div className="mt-16">
                        <PriceDisplay MRP={data?.products?.cost} OfferPrice={data?.products?.offer_cost} />
                    </div>

                </Col>
                <Col span={24} className="mt-16">
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Popconfirm
                                title="Are you sure to remove this product?"
                                onConfirm={() => removeProduct()}
                                onCancel={() => setConfirmDialogVisible(false)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <div className="blue-cta" onClick={() => setConfirmDialogVisible(true)}><DeleteOutlined /> Remove</div>
                            </Popconfirm>
                        </Col>
                        <Col>
                            <Select
                                defaultValue={1}
                                style={{ width: 73 }}
                                onChange={(quantity) => { updateCart(quantity) }}
                                value={parseInt(data?.quantity)}
                            >
                                {Array(10).fill(1).map((number, index) => {
                                    return (
                                        <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                                    )
                                }
                                )}
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr className="divider" />
        </div>
    )
}

export default CheckoutProductCard;
