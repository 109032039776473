import axios from 'axios';
import { createBrowserHistory } from 'history';
import qs from 'qs';
import { deleteLocalData, getAccessToken, getRefreshToken, removeAxiosHeaders, setAccessToken, setAxiosHeaders, setRefreshToken } from '../utils/helper';
import apiEndpoints from './apiEndPoints';

const history = createBrowserHistory({});

let isTokenrefreshing = false,
  retryReqestArray: any = [];

function onTokenRefresh(authorisationToken: any) {
  retryReqestArray.map((cb: any) => cb(authorisationToken));
}

axios.interceptors.response.use(
  (response) => {
    window.console.log(response.status);
    return response;
  },
  (error) => {
    window.console.log(error.Cancel, getAccessToken());
    if (error && error.response) {
      if (error.response.status === 401) {
        if (getAccessToken()) {
          if (getRefreshToken()) {
            if (!isTokenrefreshing) {
              removeAxiosHeaders();
              isTokenrefreshing = true;
              const params = {
                grant_type: 'refresh_token',
                refresh_token: getRefreshToken(),
              };
              axios
                .post(apiEndpoints.refreshToken, params)
                .then((response) => {
                  setRefreshToken(response.data.refresh_token);
                  setAccessToken(response.data.access_token);
                  setAxiosHeaders();
                  onTokenRefresh(response.data.access_token);
                  window.location.reload();
                })
                .catch(() => {
                  removeAxiosHeaders();
                  deleteLocalData('accessToken');
                  history.push('/');
                  window.location.reload();
                });
            }
            return new Promise((resolve) => {
              retryReqestArray.push((token: any) => {
                const originalRequest = error.config;
                originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(axios(originalRequest));
              });
            });
          } else {
            removeAxiosHeaders();
            deleteLocalData('accessToken');
            history.push('/');
            window.location.reload();
          }
        }
      }
      if (error.response.status >= 500) {
        history.push('/500');
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);


axios.interceptors.request.use((request: any) => {
  let requestPlatform = 'web';
  try {
  } catch (e) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      requestPlatform = 'm-web';
    }
  }
  request.headers['X-REQUEST-PLATFORM'] = requestPlatform;
  return request;
});

const api = {
  user: {
    requestOTP: (data: any) => axios.post(apiEndpoints.requestOTP, data).then((res) => res.data),
    verifyOTP: (data: any) => axios.post(apiEndpoints.verifyOTP, data).then((res) => res.data),
    profile: () => axios.get(apiEndpoints.userProfile).then((res) => res.data),
    updateProfile: (data: any) => axios.post(apiEndpoints.userProfile, data).then((res) => res.data),
  },
  partner: {
    getPartner: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.getPartner, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          cancelToken: cancelToken,
        },
        )
        .then((res) => res.data),

    getProducts: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.getProducts, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),

    getPartnerDetails: (params?: object) => axios.get(apiEndpoints.partnerDetails, { params: params }).then((res) => res.data),

  },
  cart: {
    manageCart: (data: object) => axios.post(apiEndpoints.manageCart, data).then((res) => res.data),
    clearCart: (id: number, params?: object) => axios.delete(apiEndpoints.manageCart + `/${id}`, { params: params }).then((res) => res.data),
    getCart: (params?: object) => axios.get(apiEndpoints.manageCart, { params: params }).then((res) => res.data),
    cartCount: (params?: object) => axios.get(apiEndpoints.cartCount, { params: params }).then((res) => res.data),
  },
  payments: {
    getPaymentLink: (data: object) => axios.post(apiEndpoints.paymentRequest, data).then((res) => res.data),
    getPaymentStatus: (data: object) => axios.post(apiEndpoints.paymentStatus, qs.stringify(data)).then((res) => res.data),
  },
  coupons: {
    purchasedCoupons: (params: object) => axios.get(apiEndpoints.purchasedCoupons, { params: params }).then((res) => res.data),
  }
};

export default api;
