import { Col, Image, Row, Select, Tooltip } from "antd";
import React, { useState } from "react";
import ReactGA from 'react-ga4';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import validity from '../../assets/icons/validity.svg';
import { updateCartCount } from "../../store/auth";
import { errorHandler } from "../../utils/helper";
import { trackData } from "../../utils/trackers";
import LoginModal from "../LoginModal/LoginModal";
import ProductDetailsModal from "../ProductDetailsModal/ProductDetailsModal";
import Button from "../UI/Button/Button";
import PriceDisplay from "../UI/PriceDisplay/PriceDisplay";
import { successNotification } from "../UI/Toast/Toast";
import './_buyProductCard.scss';

interface Props {
    data: any;
    getProducts: any;
}


const BuyProductCard: React.FC<Props> = ({ data, getProducts }) => {
    const [quantity, setQuantity] = useState(1),
        [isModalOpen, setIsModalOpen] = useState(false),
        [isLoginModalOpen, setIsLoginModalOpen] = useState(false),
        { partner } = useParams(),
        dispatch = useDispatch(),
        viewBenefits = () => {
            ReactGA.event('view_item', { value: data?.offer_cost, currency: 'INR', items: [{ item_name: data?.product_name, item_brand: data?.provider_name }] });
            setIsModalOpen(true);
        },
        addToCart = () => {
            api.cart.manageCart({
                quantity: quantity,
                category: data?.category,
                service_id: data?.service_id,
                type: 'addition',
                slug: partner,
            })
                .then(res => {
                    ReactGA.event('add_to_cart', { value: data?.offer_cost, currency: 'INR', items: [{ item_name: data?.product_name, item_variant: res?.data?.products?.plan_code, item_brand: data?.provider_name }] });
                    trackData('Add To Cart', { product: data?.product_name, value: data?.offer_cost, item_variant: res?.data?.products?.plan_code, item_brand: data?.provider_name });
                    successNotification('Product(s) added to cart');
                    dispatch(updateCartCount(partner));
                    getProducts();
                })
                .catch(err => {
                    dispatch(updateCartCount(partner));
                    if (err?.response?.status === 401) {
                        setIsLoginModalOpen(true);
                    }
                    else {
                        errorHandler(err?.response?.data);
                        getProducts();
                    }
                })
        },
        information = [
            {
                title: `Validity 12 months`,
                icon: validity,
            },
            // {
            //     title: `Coverage - ${data?.coverage_type?.[0] || "Self"}`,
            //     icon: coverage,
            // },
            // {
            //     ...(data?.plan_code &&
            //     {
            //         title: `Plan - ${data?.plan_code}`,
            //         icon: plan,
            //     }
            //     )
            // },
        ],
        confirmHandler = () => {
            setIsLoginModalOpen(false);
            addToCart();
        },
        cancelHandler = () => {
            setIsLoginModalOpen(false);
        };

    return (
        <React.Fragment>
            <ProductDetailsModal cancelHandler={() => setIsModalOpen(false)} isModalOpen={isModalOpen} data={data} />
            <LoginModal cancelHandler={() => cancelHandler()} isModalOpen={isLoginModalOpen} confirmHandler={confirmHandler} />
            <div className="buy-product-card">
                <Row>
                    <Col span={24}>
                        <img className="image" src={data?.product_image} />
                    </Col>
                    <Col span={24} className='content'>
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <div className="title">{data?.product_name} - {data?.plan_name ? data?.plan_name : data?.plan_code}</div>
                                <div className="sub-title">Powered by : <span className="provider-name">{data?.provider_name}</span></div>
                            </Col>
                            <Col span={24}>
                                <PriceDisplay MRP={data?.cost} OfferPrice={data?.offer_cost} />
                            </Col>
                            <Col span={24} className="primary-text">
                                <div>{data?.product_description}</div>
                                <span className="cta" onClick={() => viewBenefits()}>View Benefits</span>
                            </Col>
                        </Row>
                        <div className='icon-wrapper'>
                            <Row gutter={12}>
                                {information.map((info, index) => {
                                    return (
                                        <Col key={index}>
                                            <div className="icon">
                                                <Image src={info?.icon} preview={false} />
                                            </div>
                                            <div className="icon-description">{info?.title}</div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                    </Col>
                    <div className="cta-wrapper full-width">
                        <Row gutter={16} justify='end' align="middle">
                            <Col>
                                <Select
                                    defaultValue={1}
                                    style={{ width: 73, visibility: data?.product_activation_type !== 'coupon' ? 'hidden' : 'visible' }}
                                    onSelect={(value: number) => setQuantity(value)}
                                >
                                    {
                                        Array(10).fill(1).map((number, index) => {
                                            return (
                                                <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                                            )
                                        }
                                        )
                                    }

                                </Select>
                            </Col>
                            <Col>
                                {!data?.already_bought &&
                                    <Button type="primary" onClick={addToCart}>Add to cart</Button>
                                }
                                {
                                    data?.product_activation_type === 'api' && data?.already_bought &&
                                    <Tooltip title="Quantity restricted to one purchase per mobile number">
                                        <span>
                                            <Button type="primary" disabled>Add to cart</Button>
                                        </span>
                                    </Tooltip>
                                }

                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default BuyProductCard;
