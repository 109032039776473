import { Result, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import api from '../api/api';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { errorHandler } from '../utils/helper';

const Layout: React.FC = (props: any) => {
  const referrer = document.referrer,
    // { partner } = useParams(),
    [loading, setLoading] = useState(true),
    [validReferrer, setValidReferrer] = useState(false);

  useEffect(() => {
    let partner = window.location.pathname.split('/')[1]
    if (partner) {
      api.partner.getPartnerDetails({ slug: partner })
        .then((res) => {
          if (res?.data?.referral === null || res?.data?.referral?.length === 0 || res?.data?.referral?.includes(referrer)) {
            setValidReferrer(true);
            setLoading(false);
          }
          else {
            setValidReferrer(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorHandler(err?.response?.data);
        })
    }
  }, []);

  return (
    <div className="layout">
      <Header />
      {loading &&
        <Result
          // status="warning"
          icon={<Spin />}
          style={{ marginTop: '20vh', marginBottom: '20vh' }}
          title="Checking Eligiblity"
          subTitle={`Checking if you are eligible to avail these offers.`}
        />
      }      {!validReferrer && !loading &&
        <Result
          status="warning"
          style={{ marginTop: '20vh', marginBottom: '20vh' }}
          title="Invalid Referrer"
          subTitle={`You are not elgible for these offers.`}
        />
      }
      {validReferrer && !loading && props.children}
      <Footer />
    </div>
  );
};

export default Layout;
