import { LogoutOutlined } from '@ant-design/icons';
import { Badge, Col, Image, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cart from '../../assets/icons/cart.svg';
import logoShort from '../../assets/icons/logo-short.svg';
import logo from '../../assets/icons/logo.svg';
import { authActions, updateCartCount } from '../../store/auth';
import { removeAxiosHeaders, setAxiosHeaders } from '../../utils/helper';
import { trackData } from '../../utils/trackers';
import LoginModal from '../LoginModal/LoginModal';
import { successNotification } from '../UI/Toast/Toast';
import './_header.scss';

const Header: React.FC = () => {
  const navigate = useNavigate(),
    [searchParams, setSearchParams] = useSearchParams(),
    dispatch = useDispatch(),
    partner = window.location.href?.split('/')[3],
    logoutHandler = () => {
      dispatch(authActions.logout());
      removeAxiosHeaders();
      navigate(`/${partner}`);
      successNotification('Logged out successfully');
    },
    cartCount = useSelector((state: any) => state?.auth?.cartCount),
    isLoggedIn = useSelector((state: any) => state?.auth?.isLoggedIn),
    viewCart = () => {
      ReactGA.event('view_cart');
      navigate(`/${partner}/checkout`);
    },
    confirmHandler = () => {
      setIsLoginModalOpen(false);
    },
    cancelHandler = () => {
      setIsLoginModalOpen(false);
    },
    // [data, setData] = useState([] as any),
    [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setAxiosHeaders();
      if (!searchParams.get('order_id')) {
        // console.log('called')
        dispatch(updateCartCount(partner));
      }
    }
  }, [isLoggedIn])

  return (
    <div className='header'>
      {isLoginModalOpen && <LoginModal cancelHandler={() => cancelHandler()} isModalOpen={isLoginModalOpen} confirmHandler={confirmHandler} />}
      <Row justify='space-between' align='middle' className='container'>
        <Col className='show-on-web'>
          <Image src={logo} preview={false} onClick={() => navigate(`/${partner}`)} className='cta' />
        </Col>
        <Col className='show-on-mobile'>
          <Image src={logoShort} preview={false} onClick={() => navigate(`/${partner}`)} className='cta' />
        </Col>
        <Col>
          <Row gutter={32} align={'middle'} justify="end">
            {isLoggedIn &&
              <>
                <Col>
                  <Badge count={cartCount}>
                    <Image src={cart} preview={false} className="cta" onClick={() => {
                      trackData('View Cart', { location: 'header' });
                      viewCart()
                    }} />
                  </Badge>
                </Col>
                <Col>
                  <Tooltip title="Logout">
                    <LogoutOutlined className='logout-icon cta' onClick={() => logoutHandler()} />
                  </Tooltip>
                </Col>
              </>
            }
            {!isLoggedIn && <Col>
              <Image src={cart} preview={false} className="cta" onClick={() => {
                trackData('Open Login Modal', { location: 'header' });
                setIsLoginModalOpen(true);
              }} />
            </Col>}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
