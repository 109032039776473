import { Col, Image, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import failure from '../../assets/images/paymentFailure.svg';
import Button from '../UI/Button/Button';

const PaymentFailure: React.FC = () => {
    const navigate = useNavigate(),
        { partner } = useParams();
    return (
        <div>
            <Row justify='center'>
                <Col className="primary-heading">Payment Failed!</Col>
            </Row>
            <Row justify='center'>
                <Col className="subheading mt-16 text-center">Your payment was not successful. Please try again after some time.</Col>
            </Row>
            <Row justify='center' className='mt-16'>
                <Image src={failure} alt="failure" preview={false} />
            </Row>
            <Row justify='center' className='mt-32'>
                <Button type='primary' onClick={() => navigate(`/${partner}/checkout`)}>Retry Payment</Button>
            </Row>
        </div>
    )
}

export default PaymentFailure;
