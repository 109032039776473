interface Props {
    MRP?: number;
    OfferPrice?: number;
}
const PriceDisplay: React.FC<Props> = ({ MRP, OfferPrice }) => {
    return (
        <div className="price-display">
            <div className="primary-text">MRP <span className="strikethrough amount">₹{MRP?.toLocaleString('en-IN')}</span></div>
            <div className="price amount">₹{OfferPrice?.toLocaleString('en-IN')}</div>
        </div>
    )
}

export default PriceDisplay;
