import { RightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import './_tab-indicator.scss';
interface Props {
    data: any;
    changeActiveTab: any;
}

const TabIndicator: React.FC<Props> = ({ data, changeActiveTab }) => {

    return (
        <Row>
            {data?.map((item: any, index: number) => {
                return (
                    <Col onClick={() => changeActiveTab(item?.name)} key={index}>
                        <span className={`tab-indicator ${item?.isActive ? 'active' : ''}`}>{item?.title}</span>
                        {index !== data.length - 1 ? <RightOutlined className='tab-indicator-pointer' /> : null}
                    </Col>
                )
            })}
        </Row>
    )
};

export default TabIndicator;
