import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../constants/config';

export const trackData = (name: string, data?: object) => {
  if (MIXPANEL_TOKEN && MIXPANEL_TOKEN !== "NA") {
    console.log(data);
    mixpanel.init(MIXPANEL_TOKEN);
    mixpanel.track(name, data);
  }
};

export const startTimedEvent = (name: string) => {
  if (MIXPANEL_TOKEN && MIXPANEL_TOKEN !== "NA") {
    mixpanel.init(MIXPANEL_TOKEN);
    mixpanel.time_event(name);
  }
};

export const identify = (id: string) => {
  if (MIXPANEL_TOKEN && MIXPANEL_TOKEN !== "NA") {
    mixpanel.init(MIXPANEL_TOKEN);
    mixpanel.identify(id);
  }
};

export const register = (data: any) => {
  if (MIXPANEL_TOKEN && MIXPANEL_TOKEN !== "NA") {
    mixpanel.init(MIXPANEL_TOKEN);
    mixpanel.register(data);
  }
};

export const setUserProperties = (data: any) => {
  if (MIXPANEL_TOKEN && MIXPANEL_TOKEN !== "NA") {
    mixpanel.init(MIXPANEL_TOKEN);
    mixpanel.people.set(data);
  }
};

export const trackLinks = (selector: string, action: string) => {
  if (MIXPANEL_TOKEN && MIXPANEL_TOKEN !== "NA") {
    mixpanel.init(MIXPANEL_TOKEN);
    mixpanel.track_links(selector, action);
  }
};
