import CustomModal from '../Modal/Modal';
import './_tncModal.scss';


interface Props {
    isModalOpen?: boolean;
    cancelHandler?: () => void;
    confirmHandler?: () => void;
    data?: any;
}

const terms_and_conditions = [
    'The promo code has a validity of one year from the date of distribution',
    'The promo code is non-transferable and applicable only for employees and family',
    'Members as selected under health and wellness services by your organization',
    'The promo code can be used only once by each member',
    'The promo code is not valid on any other plan apart from the plan mentioned above',
    'The promo code is valid only on bookings made via the web link that has been shared by Healthysure',
    'The service and price agreed before the payment will remain the same throughout the validity period',
    'All other T&C mentioned by the partners are applicable at a service-level',
], termsAndConditionsContent = (
    <ul>
        {terms_and_conditions.map((item, index) => {
            return (
                <li className='list-item' key={index}>
                    {item}
                </li>
            );
        })}
    </ul>
)
const TnCModal: React.FC<Props> = ({ isModalOpen, cancelHandler, data }) => {
    return (
        <CustomModal
            isModalOpen={isModalOpen}
            cancelHandler={cancelHandler}
            modalClass='tnc-modal'
            width={580}
            title='Terms and Conditions'
            children={termsAndConditionsContent}
        />
    )
}

export default TnCModal;
