import { Col, Image, Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api/api';
import hnw from '../assets/images/healthandwellness.svg';
import FooterBtn from '../components/Modal/FooterBtn';
import LockModal from '../components/Modal/LockModal';
import ProductCard from '../components/ProductCard/ProductCard';
import ProductDetailsModal from '../components/ProductDetailsModal/ProductDetailsModal';
import Button from '../components/UI/Button/Button';
import { BRAND_NAME, HDFCCOUPON, MAIN_SITE_URL } from '../constants/config';
import { errorHandler } from '../utils/helper';
import { trackData } from '../utils/trackers';


const Landing: React.FC = () => {
  const navigate = useNavigate(),
    [isModalOpen, setIsModalOpen] = useState(false),
    // [products, setProducts] = useState([] as any),
    [data, setData] = useState([] as any),
    [productData, setProductData] = useState([] as any),
    { partner } = useParams();


  useEffect(() => {
    window.scrollTo(0, 0);
    let source = axios.CancelToken.source();
    api.partner.getPartner({ slug: partner }, source.token)
      .then((res) => {
        if (res?.data?.length !== 0) {
          setData(res?.data?.[0]);
          api.partner.getProducts({ slug: partner, is_landing_page: true }, source.token)
            .then((res) => {
              setProductData(res?.data?.results?.[0]?.products);
            })
            .catch((err) => {
              console.log(err);
              errorHandler(err?.response?.data)
            })
        }
        else {
          window.location.href = MAIN_SITE_URL;
        }
      })
      .catch((err) => {
        console.log(err);
        errorHandler(err?.response?.data)
      })
  }, [])

  return (
    <div className='page' style={{ paddingTop: "0", paddingBottom: "0" }}>
      <LockModal />
      <ProductDetailsModal cancelHandler={() => setIsModalOpen(false)} isModalOpen={isModalOpen} />

      <div className='intro-card'>
        <Row gutter={[0, 36]} justify='space-between' align='middle' className='container'>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <h1 className='primary-heading'>Healthysure's <div className='highlight'>Health & Wellness</div></h1>
            <div className='sidebar-text'>
              Making healthcare affordable and accessible with a wide range of preventive and reactive health & wellness offerings
            </div>
            <div className='mt-64 show-on-web'>
              <Button className='' type='primary' onClick={() => {
                trackData('Explore Offerings', { location: 'hero' });
                navigate(`/${partner}/offerings`)
              }
              }>
                Explore Offerings
              </Button>
              {partner === 'hdfc' && <div><br />Pay with HDFC debit or credit card and use the code <b>{HDFCCOUPON}</b> to get 50% off on your all products</div>}
            </div>
            <Row justify='center' className='show-on-mobile mt-32'>
              <Button className='' type='primary' onClick={() => {
                trackData('Explore Offerings', { location: 'hero' });
                navigate(`/${partner}/offerings`)
              }
              }>
                Explore Offerings
              </Button>
              {partner === 'hdfc' && <div><br />Pay with HDFC debit or credit card and use the code <b>{HDFCCOUPON}</b> to get 50% off on your all products</div>}
            </Row>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Image className='hero-image' src={hnw} preview={false} alt={''} />
            <div className='text-center mt-4 in-partnership-with'>In partnership with</div>
            <Row justify='center' className='mt-4'>
              <Image className='' src={data?.partner_detail?.logo} preview={false} alt={`${data?.partner_detail?.name} x ${BRAND_NAME}`} width={80} height={'auto'} />
            </Row>
          </Col>
        </Row>
      </div>

      <div className='partners-card container'>
        <Row justify='center'>
          <Col>
            <div className='secondary-heading'>Our service partners</div>
          </Col>
        </Row>
        <Row justify='center' className='mt-48' align='middle'>
          {data?.provider_detail?.map((partner: any, index: number) => (
            <Col key={index}>
              <Image className='partner-logo' src={partner?.logo_url} preview={false} alt={`Healthysure x ${partner?.brand_name}`} height='auto' />
            </Col>
          ))}
        </Row>
      </div>

      <div className='deals-card'>
        <div className="container">
          <div className='secondary-heading text-center'>Select deals, exclusively curated!</div>
          <Row className='mt-32' gutter={[24, 24]}>
            {productData
              // ?.filter((product:any)=>product?.is)
              ?.map((deal: any, index: number) => (
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} key={index}>
                  <ProductCard data={deal} />
                </Col>
              ))}
          </Row>
        </div>
      </div>

      <FooterBtn />

    </div >
  )
};

export default Landing;
