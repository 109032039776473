import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Checkout from '../pages/Checkout';
import Landing from '../pages/Landing';
import Offerings from '../pages/Offerings';
import PostPayment from '../pages/PostPayment';
import RedirectToHome from '../pages/RedirectToHome';



const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/:partner/*">
        <Route path="" element={<Landing />} />
        <Route path="offerings" element={<Offerings />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="post-payment" element={<PostPayment />} />
      </Route>
      <Route path="*" element={<RedirectToHome />} />
    </Routes>
  );
};

export default Router;
